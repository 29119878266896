export class SidePanel {
  constructor(_options) {
    this.sidePanel = document.querySelector('.side');
    this.ACTIVE_CLASS = 'active';
    this.BODY_CLASS = 'side-active';
    this.openerSelector = _options.openSelector || '.side-open';

    if (this.sidePanel) {
      this.sideCloseEl = this.sidePanel.querySelector('.side__close');
      this.sideCloseEl?.addEventListener('click', this.handleClose);

      document.addEventListener('click', (e) => {
        if (e.target?.closest('.side-open')) {
          this.handleOpen(e);
        }
      });
    }
  }

  isOpened = () => {
    return this.sidePanel?.classList.contains(this.ACTIVE_CLASS);
  };

  handleOpen = (e) => {
    e.preventDefault();
    e.stopPropagation();

    this.sidePanel?.classList.add(this.ACTIVE_CLASS);
    document.documentElement.classList.add(this.BODY_CLASS);

    document.addEventListener('click', this.handleOutsideClick);
  };

  handleClose = (e) => {
    e.preventDefault();
    this.sidePanel?.classList.remove(this.ACTIVE_CLASS);
    document.documentElement.classList.remove(this.BODY_CLASS);

    document.removeEventListener('click', this.handleOutsideClick);
  };

  handleOutsideClick = (e) => {
    if (!this.sidePanel?.contains(e.target) && this.isOpened()) {
      this.handleClose(e);
    }
  };
}
